import './assets/styles/App.css';
import {  Suspense } from 'react';
import { ToastContainer } from 'react-toastify';
import { AuthProvider } from './modules/moduleAuth/components/Auth';
import { RouterProvider} from 'react-router-dom'

import router from './router';
import LoaderModal from './components/LoaderModal/LoaderModal';
import Logout from './modules/moduleAuth/components/Logout';

function App() {

  return (
    <>
        <AuthProvider>

      <ToastContainer />

        <Suspense fallback={<LoaderModal/>}> 
        <RouterProvider router={router} />
        </Suspense> 
        <Logout />
        </AuthProvider>

    </>
  );
}

export default App;
