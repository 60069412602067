import './AutoCompleteText.css';
import { useTranslation } from 'react-i18next';
import LoadingResults from '../../../../../components/LoadingResutls/LoadingResults';


const AutoCompleteText = ({loadingCity,titleInput,loadingWriting,handleClickColor,Icon,placeholder,inputf,options,isLoading,errorApi,query,loadingTimerSelected,errorValue,value,field,valueSelect,handleInputText,resetPorts,refSearch,refInput,refDropDown}) => {

  const {t,i18n} = useTranslation();



  const handleChangeText= (e)=>{

      handleInputText(field,e.target.value)



  }


  const chooseValue = (item)=>{

    valueSelect(field,item);

  }

  return (
    <>
    {titleInput &&

    <p className='autocomplete-title'>
      {titleInput}
    </p>

    }
    <div className='automcomplete-searchbox' >
      {/* if name (${value?.port_code}) else ${value?.origin?.countries_code}   */}
      {/* input text */}
      <div ref={refInput}   className='automcomplete-searchbox__row' style={{borderColor:(errorValue&&!value) ?'red' : null}} >
      <span className='automcomplete-searchbox__row-icon'>
            {Icon}
      </span>
        <input onClick={handleClickColor} ref={refSearch} onChange={handleChangeText} title="" className='automcomplete-searchbox__row-input' type='text' placeholder={placeholder} autoComplete='off' />



        { (( value && !value?.description)) &&
        <div  className='automcomplete-searchbox__row-details'>
        <span className='automcomplete-searchbox__row-details-code'>
          {value?.name ?
            `(${value?.port_code || value?.airport_code})`
            :
            `${value?.origin?.countries_code}`
          }
        </span>

        {(value?.origin?.ImageURL || value?.port_flag) &&
          <img className='automcomplete-searchbox__row-img' src={value?.origin?.ImageURL || value?.port_flag } alt='flag icon' />
        }
        </div>
        }


      </div>

      {/* dropdown */}
      <div   className='automcomplete-searchbox__resultsbox '>
        {/* map */}
        {
        query?.length >=2 && loadingWriting===false ?
        <>
        {
        options?.length > 0   ?
        <ul  className='fade-enter automcomplete-searchbox__resultsbox-box' ref={refDropDown}>

          {
          options.map((item ,index) => {
            return(
            <li  key={index} onClick={()=>chooseValue(item)} >

              {
                item?.name ?
                <div className='automcomplete-searchbox__resultsbox-info'>

                {/* <div style={{display:'flex' , flexDirection:'column'}}> */}
                <span>{item?.name}</span>
                {/* <span className='ghgh'>(ليفربول)</span> */}
                {/* </div> */}



                <div className='automcomplete-searchbox__resultsbox-info__text'>
                  <span>({item?.port_code || item?.airport_code})</span>
                  <div className='automcomplete-searchbox__resultsbox-info__menaimg'>
                    {Icon}
                  </div>
                </div>

                </div>

                :
                <div className='automcomplete-searchbox__resultsbox-info'>

                  <span>{item?.origin?.label ||item?.origin?.label_ar ||item?.description }</span>

                <div className='automcomplete-searchbox__resultsbox-info__text'>
                  <span>{item?.origin?.countries_code}</span>
                  {
                    item?.origin?.ImageURL ?
                  <img src={item?.origin?.ImageURL} alt='country flag'/>
                  :
                  Icon
                  }
                </div>
                </div>
              }

            </li>
            )
          })
        }

        </ul>
        :
      <>
        {loadingTimerSelected ? null :
        isLoading ===false &&(loadingCity===false|| loadingCity===undefined) && (errorApi ===null) && query?.length >= 2 && options?.length===0 &&
        <p  className='fade-enter automcomplete-searchbox__resultsbox-box automcomplete-searchbox__resultsbox-box__NoResult'  >
        {t('labelDutiesCalculator.noOptions')}
        </p>
        }


        {
        errorApi !==null  &&
          <div  className='automcomplete-searchbox__resultsbox-box error__Port fade-enter'>
            {errorApi}
          </div>
        }

      </>

        }

        {
        (isLoading || loadingTimerSelected || loadingCity) &&
        <div   className='automcomplete-searchbox__resultsbox-box fade-enter'>
          <LoadingResults/>
        </div>
        }

        </>


        :
        null

      }


      </div>


    </div>
    </>
  )
}

export default AutoCompleteText
